import { Input } from "antd";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Links from "next/link";
import call from "../../assets/images/footer/Call.png";
import loca from "../../assets/images/footer/Location.png";
import mes from "../../assets/images/footer/Message.png";
import fb from "../../assets/images/footer/fb.png";
import inLink from "../../assets/images/footer/in.png";
import logo from "../../assets/images/footer/logonew.png";
import ytb from "../../assets/images/footer/ytb.png";
import button from "../../assets/images/icon/Button.png";
import email from "../../assets/images/icon/Message.png";
const FooterPresenter = () => {
  const { t } = useTranslation(["translation"]);

  return (
    <section className="bg-[url('/bg4.webp')] bg-no-repeat bg-center mx-auto text-center  w-full py-[2rem] px-0 1lg:pb-[1rem] 11xl:px-[2rem] 2lg:p-[1rem] 2lg:bg-[url('/bg10.webp')] mt-auto">
      <div className="max-w-[1200px] mx-auto 2lg:py-[2rem]">
        <div className="flex  gap-12 2lg:flex-col 1sm:gap-[2rem]">
          <div className="flex flex-col text-left gap-6 w-[35%] 2lg:flex-row 2lg:w-full">
            <div>
              <Image src={logo} alt="" />
            </div>
            <div className="w-full">
              <div className="mb-4">
                <h2 className="text-h4 text-white uppercase font-bold 1lg:text-[18px] 2lg:text-import14">
                  {t("FOOTER1")}
                </h2>
                <h3 className="mt-[1rem] text-[16px] text-white uppercase font-bold 1lg:text-[18px] 2lg:text-import14">
                  Hotline Wasabi: 1900 998818
                </h3>
              </div>
              <div className="flex items-center gap-4">
                <Image
                  src={fb}
                  onClick={() => window.open("https://www.facebook.com/qdtek")}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <Image
                  src={inLink}
                  onClick={() =>
                    window.open("https://www.linkedin.com/in/quang-dung-tek/")
                  }
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <Image
                  src={ytb}
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC0-Fm0AeGN6tBKU0n_n36cQ"
                    )
                  }
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <div className="flex  gap-4 2lg:flex-col-reverse justify-between">
            <div className="w-[50%] 2lg:w-wFull ">
              <div className="text-left">
                <h2 className="text-p text-white font-bold mb-4">
                  {" "}
                  {t("FOOTER8")}
                </h2>
                <div className="flex items-start gap-0 w-full 2lg:gap-[5px]">
                  <div className="w-[5%] mr-[.5rem]">
                    <Image src={loca} alt="" />
                  </div>
                  <div className="mb-[20px] w-[90%]">
                    <p className="text-p text-white font-normal 2lg:text-import14">
                      {t("FOOTER9")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-0 mb-[10px] 2lg:gap-[5px]">
                  <div className="w-[5%]  mr-[.5rem]">
                    <Image src={call} alt="" />
                  </div>
                  <div className="w-[100%]">
                    <p className="text-p text-white font-normal w-[90%] 2lg:text-import14">
                      {t("FOOTER10")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-0 mb-[10px] w-[100%] 2lg:gap-[5px]">
                  <div className="w-[5%]  mr-[.5rem]">
                    <Image src={mes} alt="" />
                  </div>
                  <div>
                    <p className="text-p text-white font-normal w-[90%] 2lg:text-import14">
                      info@qdtek.vn
                    </p>
                  </div>
                </div>
                <h2 className="text-p text-white font-bold mb-4 w-[90%] 1sm:mt-6">
                  {t("FOOTER11")}
                </h2>
                <div className="flex items-start gap-0 w-full 2lg:gap-[5px]">
                  <div className="w-[5%] mr-[.5rem]">
                    <Image src={loca} alt="" />
                  </div>
                  <div className="mb-[20px] w-[90%]">
                    <p className="text-p text-white font-normal 2lg:text-import14">
                      {t("FOOTER12")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-0 mb-[10px] w-full 2lg:gap-[5px] ">
                  <div className="w-[5%]  mr-[.5rem]">
                    <Image src={call} alt="" />
                  </div>
                  <div>
                    <p className="text-p text-white font-normal 2lg:text-import14">
                      {t("FOOTER13")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-0 w-full 2lg:gap-[5px]">
                  <div className="w-[5%]  mr-[.5rem]">
                    <Image src={mes} alt="" />
                  </div>
                  <div>
                    <p className="text-p text-white font-normal 2lg:text-import14">
                      info@qdtek.vn
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[45%] 2lg:w-[25%] 2lg:w-wFull 2lg:flex 2lg:flex-col-reverse">
              <div className="text-left flex justify-between 1sm:gap-4">
                <div className="w-full">
                  <Links href="https://wasabi.com/about/" prefetch={false}>
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 cursor-pointer">
                      {t("FOOTER2")}
                    </h2>
                  </Links>
                  <Links href="/" prefetch={false}>
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 cursor-pointer 1sm:mb-[0.8rem!important]">
                      {t("FOOTER3")}
                    </h2>
                  </Links>

                  <Links href="/partners" prefetch={false}>
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 1sm:mb-[0.8rem!important] cursor-pointer">
                      {t("FOOTER4")}
                    </h2>
                  </Links>
                  <Links
                    href="/#pricing"
                    scroll={true}
                    className="text-link cursor-pointer"
                  >
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 1sm:mb-[0.8rem!important] cursor-pointer">
                      {t("FOOTER5")}
                    </h2>
                  </Links>
                  <Links
                    href="/news"
                    className=" cursor-pointer"
                    prefetch={false}
                  >
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 cursor-pointer 1sm:mb-[0.8rem!important]">
                      {t("FOOTER6")}
                    </h2>
                  </Links>

                  <Links
                    href="/trial-register"
                    className="text-link cursor-pointer"
                  >
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 1sm:mb-[0.8rem!important] cursor-pointer">
                      {t("FOOTER15")}
                    </h2>
                  </Links>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wasabi.com/legal/terms-of-use"
                    className="text-link cursor-pointer"
                  >
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 1sm:mb-[0.8rem!important]">
                      {t("FOOTER14")}
                    </h2>
                  </a>
                </div>
                <div className="w-full">
                  <Links href="/customer-agreement" className=" cursor-pointer">
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 cursor-pointer 1sm:mb-[0.8rem!important]">
                      {t("FOOTER17")}
                    </h2>
                  </Links>
                  <Links href="/wasabi-service-level-agreement">
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 cursor-pointer 1sm:mb-[0.8rem!important]">
                      {t("FOOTER18")}
                    </h2>
                  </Links>
                  <Links href="/privacy-policy">
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 cursor-pointer 1sm:mb-[0.8rem!important]">
                      {t("FOOTER19")}
                    </h2>
                  </Links>
                  <Links href="/acceptable-use-policy">
                    <h2 className="text-p text-white font-medium mb-4 2lg:text-import13 cursor-pointer 1sm:mb-[0.8rem!important]">
                      {t("FOOTER20")}
                    </h2>
                  </Links>
                </div>
              </div>
              <div className="mt-10 2lg:mt-0 2lg:mb-10 1sm:mb-[2rem!important]">
                <div>
                  <p className="text-p text-white font-bold mb-4 text-left 2lg:text-import14 ">
                    {t("NEWLE")}
                  </p>
                </div>
                <div className="flex  ">
                  <Input
                    className="h-[42px] rounded-[4px] font-normal text-p12 pl-[10px] 22lg:w-full 1lg:h-[40px] 2lg:h-[43px!important]"
                    placeholder={t("HERO90")}
                    prefix={
                      <>
                        <Image src={email} alt="" />
                      </>
                    }
                  />
                  <div className="ml-[3px] cursor-pointer">
                    <Image src={button} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterPresenter;
